@font-face {
    font-family: 'PFBeauSansPro-Bold';
    src: url('../fonts/PFBeauSansPro-Bold/PFBeauSansPro-Bold.eot');
    src: url('../fonts/PFBeauSansPro-Bold/PFBeauSansPro-Bold.eot?#iefix') format('embedded-opentype'),
         url('../fonts/PFBeauSansPro-Bold/PFBeauSansPro-Bold.woff2') format('woff2'),
         url('../fonts/PFBeauSansPro-Bold/PFBeauSansPro-Bold.woff') format('woff'),
         url('../fonts/PFBeauSansPro-Bold/PFBeauSansPro-Bold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'PFBeauSansPro-Light';
    src: url('../fonts/PFBeauSansPro-Light/PFBeauSansPro-Light.eot');
    src: url('../fonts/PFBeauSansPro-Light/PFBeauSansPro-Light.eot?#iefix') format('embedded-opentype'),
         url('../fonts/PFBeauSansPro-Light/PFBeauSansPro-Light.woff2') format('woff2'),
         url('../fonts/PFBeauSansPro-Light/PFBeauSansPro-Light.woff') format('woff'),
         url('../fonts/PFBeauSansPro-Light/PFBeauSansPro-Light.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'PFDinDisplayPro-Light';
    src: url('../fonts/PFDinDisplayPro-Light/PFDinDisplayPro-Light.eot');
    src: url('../fonts/PFDinDisplayPro-Light/PFDinDisplayPro-Light.eot?#iefix') format('embedded-opentype'),
         url('../fonts/PFDinDisplayPro-Light/PFDinDisplayPro-Light.woff2') format('woff2'),
         url('../fonts/PFDinDisplayPro-Light/PFDinDisplayPro-Light.woff') format('woff'),
         url('../fonts/PFDinDisplayPro-Light/PFDinDisplayPro-Light.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'PFDinDisplayPro-Med';
    src: url('../fonts/PFDinDisplayPro-Med/PFDinDisplayPro-Med.eot');
    src: url('../fonts/PFDinDisplayPro-Med/PFDinDisplayPro-Med.eot?#iefix') format('embedded-opentype'),
         url('../fonts/PFDinDisplayPro-Med/PFDinDisplayPro-Med.woff2') format('woff2'),
         url('../fonts/PFDinDisplayPro-Med/PFDinDisplayPro-Med.woff') format('woff'),
         url('../fonts/PFDinDisplayPro-Med/PFDinDisplayPro-Med.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}




@mixin maxWidth($maxWidth){
	max-width: $maxWidth;
	margin: 0 auto;
}
@mixin disInline(){
	display: inline-block;
	vertical-align: top;
}
@mixin order_btn($paddingTop,$paddingLeft){
	color: #fff;
	font-family: 'PFBeauSansPro-Bold';
	font-size: 14px;
	line-height: 38px;
	text-transform: uppercase;
	border: 1px solid white;
	border-radius: 20px;
	padding: $paddingTop $paddingLeft;
}
@mixin dog_white(){
	position:relative;
	z-index: 1;
	&:before{
		content: "";
		display: block;
		position:absolute;
		z-index: 2;
		left: -150px;
		top: -58px;
		background: url('../img/dog-head-white.png') no-repeat;
		background-size: 100% 100%;
		width: 154px;
		height: 132px;
	}
	&:after{
		content: "";
		display: block;
		position:absolute;
		z-index: 2;
		right: -150px;
		top: -47px;
		background: url('../img/dog-ass-white.png') no-repeat;
		background-size: 100% 100%;
		width: 138px;
		height: 124px;
	}
}
@mixin dog_black(){
	position:relative;
	z-index: 1;
	&:before{
		content: "";
		display: block;
		position:absolute;
		z-index: 2;
		left: -150px;
		top: -58px;
		background: url('../img/dog-head-black.png') no-repeat;
		background-size: 100% 100%;
		width: 192px;
		height: 132px;
	}
	&:after{
		content: "";
		display: block;
		position:absolute;
		z-index: 2;
		right: -150px;
		top: -47px;
		background: url('../img/dog-ass-black.png') no-repeat;
		background-size: 100% 100%;
		width: 184px;
		height: 124px;
	}
}
@mixin h__title($size, $lineHeight){
	font-family: 'PFBeauSansPro-Bold';
	font-size: $size;
	line-height: $lineHeight;
	text-transform: uppercase;
	text-align:center;
}

@-webkit-keyframes dogs {
	0%{width:1px;height:76px}
 100%{width:100%;height:auto}
}
@keyframes dogs{
 0%{width:1px;height:76px}
 100%{width:100%;height:auto}
}
@-webkit-keyframes dogs_two {
	0%{width:1px;height:107px}
 100%{width:100%;height:auto}
}
@keyframes dogs_two{
 0%{width:1px;height:107px}
 100%{width:100%;height:auto}
}
@-webkit-keyframes dogs_three {
	0%{width:1px;height:180px}
 100%{width:100%;height:auto}
}
@keyframes dogs_three{
 0%{width:1px;height:180px}
 100%{width:100%;height:auto}
}
@-webkit-keyframes dogs_text {
	0%{opacity:0}
	50%{opacity:0}
 	100%{opacity:1}
}
@keyframes dogs_text{
	0%{opacity:0}
	50%{opacity:0}
 	100%{opacity:1}
}
.dogs{
	-webkit-animation: dogs 1s linear;
	animation: dogs 1s linear;
}
.dogs_two{
	-webkit-animation: dogs 1s linear;
	animation: dogs 1s linear;
}
.dogs_three{
	-webkit-animation: dogs 1s linear;
	animation: dogs 1s linear;
}
.dogs_text{
	-webkit-animation: dogs_text 1.5s linear;
	animation: dogs_text 1.5s linear;
}
*{
	margin: 0;
	box-sizing:border-box;
}
section{
	@include maxWidth(1920px);
}
a {
	text-decoration: none;
	cursor:pointer;
}
li{
	list-style-type: none;
}
.wrapper-section {
	@include maxWidth(1170px);
	position:relative;
	z-index: 0;
}
// Selling Display
.selling-lr{
	background: url("../img/selling-bg.png") no-repeat;
	background-position: center top;
	&:before{
		position:absolute;
		z-index: 1;
		display: block;
		content:'';
		width: 100%;
		height: 1px;
		left: 0;
		top:90px;
		border-bottom: 1px solid rgba(255,255,255,0.25);
	}
}
.main-header {
	height: 90px;
	
}
.main-header__logo {
	@include disInline();
	color: #fff;
	font-family: 'PFBeauSansPro-Bold';
	font-size: 24px;
	line-height: 38px;
	text-transform: uppercase;
	padding:26px 0 0;
}
.main-header__logo span{
	font-family: 'PFBeauSansPro-Light';
	font-size: 10px;
	line-height: 30px;
	vertical-align: top;
}
.main-nav {
	@include disInline();
}
.header-top__button-menu {
    display: none;
}
.header-top__button-menu span {
    display: block;
    width: 30px;
    height: 4px;
    background-color: #fefefd;
    margin: 0 0 4px;
}
.main-nav ul{
	padding: 28px 0 0 92px;
}
.main-nav li{
	@include disInline();
	margin-left:42px;
}
.main-nav a{
	color: #fff;
	font-family: 'PFBeauSansPro-Light';
	font-size: 14px;
	line-height: 38px;
	text-transform: uppercase;
}
.main-nav a:hover{
	opacity: 0.5;
	color: #ffcb2d;
}
.main-nav a:active{
	opacity:1;
	color: #ffcb2d;
}
.order-lr{
	@include disInline();	
	@include order_btn(0px, 26px);
	margin-top: 25px;
	float: right;
	&:hover{
		color: #ffcb2d;
		border: 1px solid #ffcb2d; 
	}
	&:active{
		color: #333;
		border: 1px solid #ffcb2d; 
		background: #ffcb2d; 
	}
}
.selling-lr__title {
	@include maxWidth(927px);
	color: #fff;
	font-family: 'PFBeauSansPro-Bold';
	font-size: 70px;
	line-height: 74px;
	text-transform: uppercase;
	text-align: center;
	padding:223px 0 38px;
}
.selling-lr__dog-text {
	color: #fff;
	font-family: 'PFDinDisplayPro-Light';
	font-size: 30px;
	line-height: 38px;
	@include maxWidth(800px);
	text-align:center;
	@include dog_white();
}

.selling-lr__see-examples {
	display: block;
	background-color: #ffcb2d;
	border-radius: 31px;
	max-width: 290px;
	margin: 132px auto;
	text-align: center;
	padding: 9px 0;
	color: #333;
	font-family: 'PFBeauSansPro-Bold';
	font-size: 18px;
	line-height: 38px;
	text-transform: uppercase;
	border: 1px solid #ffcb2d;
	&:hover{
		color: #ffcb2d;
		border: 1px solid #ffcb2d;
		background: rgba(0,0,0,0) 
	}
	&:active{
		color: #333;
		border: 1px solid #ffcb2d; 
		background-color: #ffc000;
	}
}
.selling-lr__wrapper-mouse {
	text-align:center;
	padding-bottom: 18px;
}
// Selling Display

// Bussines Display
.bussines-lr .wrapper-section{
	padding-bottom: 126px;
}
.bussines-lr__title {
	@include h__title(50px, 80px);
	padding-top: 99px;
}
.bussines-lr__title span{
	display:block;
}
.bussines-lr__title-bottom {
	font-family: 'PFDinDisplayPro-Light';
	font-size: 28px;
	line-height: 46px;
	text-align:center;
	margin-bottom: 92px;
}
.bussines-column {
	@include disInline();
	width:24.5%;
	text-align: center;
}
.bussines-column img{
	margin-bottom: 20px;
}
.bussines-column p{
	color: #333;
	font-family: 'PFDinDisplayPro-Light';
	font-size: 18px;
	line-height: 26px;
	margin-top: 2px;
}
.bussines-column p span{
	display: block;
}
.bussines-lr__dog-text {
	color: #333;
	font-family: 'PFDinDisplayPro-Light';
	font-size: 28px;
	line-height: 38px;
	@include maxWidth(796px);
	text-align:center;
	@include dog_black();
	margin: 154px auto 0;	
	&:before{
		top:-25px;
		left: -185px;
	}
	&:after{
		top:-18px;
		right: -185px;
	}
}
// Bussines Display

// Why-work Display
.why-work-lr {
	background-color: #353334;
}
.slick {
	position:relative;
	z-index:1;
}
.slick .item {
	width: 85vw !important;
	max-width: 1411px;
	margin: 0;
	position:relative;
	z-index:1;
}
.slick .item:before {
	position:relative;
	z-index:2;
	background: rgba(56,39,23,0.5);
	width:100%;
	height:100%;
	content: "";
	display:block;
	top:0;
	left:0;
}
.slick .item.first {
	background: url('../img/slick-img1.png') no-repeat;
	height:950px;
	background-size: 100% 100%;
}
.slick .item.second {
	background: url('../img/slick-img2.jpg') no-repeat;
	background-size: 100% 100%;
 	height:950px;
}
.slick .item.third {
	background: url('../img/slick-img3.png') no-repeat;
	height:950px;
	background-size: 100% 100%;
}
.item__title{
	color: #fff;
	font-family: 'PFBeauSansPro-Bold';
	font-size: 50px;
	line-height: 80px;
	text-transform: uppercase;
	position:absolute;
	z-index: 100;
	top: 98px;
	right: 26px;
}
.item__cause{
	color: #fff;
	font-family: 'PFBeauSansPro-Bold';
	font-size: 50px;
	line-height: 60px;
	text-transform: uppercase;
	position:absolute;
	z-index: 100;
	top: 438px;
	left: 371px;
}
.item__cause span{
	display: block;
}
.item__list {
	color: #fff;
	font-family: 'PFDinDisplayPro-Light';
	font-size: 22px;
	line-height: 28px;
	position:absolute;
	z-index: 100;
	top: 310px;
	left: 698px;
	border-left: 1px solid #fff;
	border-radius: 20px;
	padding: 55px 0 55px 70px;
}
.item__list span{
	font-family: 'PFDinDisplayPro-Med';
}
.item__list li{
	list-style-type:none;
	max-width:341px;
	position:relative;
	z-index:101;
	margin-bottom: 48px;
}
.item__list li:last-child{
	margin-bottom: 0px;
}
.item__list li:before{
	display:block;
	position: absolute;
	z-index: 102;
	left: -40px;
	top: 0; 
	color: #fff;
	font-family: 'PFBeauSansPro-Bold';
	font-size: 30px;
	line-height: 28px;
	width: 30px;
	height: 30px;
}
.item__list li.first:before{
	content: "1.";
}
.item__list li.second:before{
	content: "2.";
}
.item__list li.third:before{
	content: "3.";
}
.slick .slick-prev {
	display: none;
}
.slick .slick-next{
	background: url("../img/slick-next-arrow.png") no-repeat;
	background-size: 100% 100%;
	width: 60px;
	height: 60px;
	right: 20%;
	top: 50%;
	margin-top: -30px;
}
.slick .slick-next:before{
	display: none;
}
// Why-work Display

// Engine Display
.engine-lr__title {
	color: #333;
	@include h__title(50px, 60px);
	@include maxWidth(630px);
	padding: 120px 0 79px;
}
.engine-lr__text {
	color: #333;
	font-family: 'PFDinDisplayPro-Light';
	font-size: 25px;
	line-height: 34px;
	text-align:center;
}
.engine-lr__text span{
	display: block;
}
.engine-column {
	@include disInline();
	width: 49%;
	position:relative;
	z-index: 1;
	text-align:left;
}
.engine-column img{
	position:relative;
	z-index: 2;
}
.engine-column:after{
	position:absolute;
	z-index: 3;
	display:block;
	background: url('../img/engine-thinks.png') no-repeat;
	background-size: 100% 100%;
}
.engine-column:before{
	position:absolute;
	z-index: 4;
	display:block;
	color: #333;
	font-family: 'PFDinDisplayPro-Light';
	font-size: 16px;
	line-height: 24px;
	text-align:center;
}
.engine-column.first{
	padding-top:36px;
}
.engine-column.first:after{
	content:"";
	width: 231px;
	height: 216px;
	top: -137px;
	left: 82px;
}
.engine-column.first:before{
	content:"Я не хочу выбирать продукт, курс, компанию, анализируя длинный список выгод и отличий от конкурентов.";
	width: 184px;
	height: 216px;
	top: -90px;
	left: 105px;
}
.engine-column.second{
	text-align:right;
	padding-right: 31px;
}
.engine-column.second img{
	top: -19px;
}
.engine-column.second:after{
	content:"";
	width: 181px;
	height: 169px;
	top: -137px;
	right: 109px;
	transform: rotateY(180deg);
}
.engine-column.second:before{
	content:"Это МОЙ продукт? Это МОЙ человек?";
	width: 140px;
	height: 169px;
	top: -85px;
	right: 125px;
}
.engine-column.third{
	padding: 143px 0 0 228px;
}
.engine-column.third:after{
	content:"";
	width: 214px;
	height: 200px;
	top: -15px;
	right: 50px;

}
.engine-column.third:before{
	content:"Я хочу прочитать историю, из которой эти преимущества будут очевидны.";
	width: 170px;
	height: 200px;
	top: 30px;
	right: 70px;

}
.engine-column.four{
	text-align:right;
	padding: 124px 150px 0 0;
}
.engine-column.four:after{
	content:"";
	width: 270px;
	height: 251px;
	top: -65px;
	left: 80px;
	transform: rotateY(180deg);
}
.engine-column.four:before{
	content:"Историю, которая покажет, что это за продукт, какой философии придерживаются его создатели, для кого он предназначен.";
	width: 220px;
	height: 251px;
	top: 0px;
	left: 110px;
}
// Engine Display

// What-we-say Display
.what-we-say-lr .wrapper-section{
	padding-bottom: 37px;
}
.what-we-say__title {
	color: #333;
	@include h__title(50px, 60px);
	@include maxWidth(701px);
	padding-top: 203px;
}
.what-we-say__title span{
	display:block;
}
.what-we-say__list {
	color: #333;
	font-family: 'PFDinDisplayPro-Light';
	font-size: 25px;
	line-height: 30px;
	@include maxWidth(851px);
	padding: 51px 0 0;
	text-align:center;
	margin-bottom: 84px;
}
.what-we-say__list li{
	@include disInline;
	width: 19.2%;
	position:relative;
	z-index: 1;
}
.what-we-say__list li:before{
	position:absolute;
	z-index: 2;
	display:block;
	content:"";
	width: 16px;
	height: 16px;
	background-color: #f9f9f9;
	border: 2px solid #c8c8c8;
	border-radius:50%;
	bottom: -20px;
	left:50%;
	margin-left: -8px;
}
.what-we-say__list li:after{
	position:absolute;
	z-index: 2;
	display:block;
	content:"";
	width: 130px;
	height: 1px;
	background-color: #c8c8c8;
	bottom: -12px;
	left:63%;
}
.what-we-say__list li.active:before{	
	background-color: #ffcb2d;
	border: 2px solid #ffcb2d;
}
.what-we-say__list li.active:after{
	background-color: #ffcb2d;
}
.what-we-say__list li:last-child:after{
	display:none;
}
.what-we-say__your-history {
	color: #333;
	font-family: 'PFDinDisplayPro-Light';
	font-size: 28px;
	line-height: 30px;
	@include maxWidth(470px);
	position:relative;
	z-index: 1;
	text-align:center;
	margin-bottom:65px;
}
.what-we-say__your-history:after {
	position:relative;
	z-index:2;
	display:block;
	content: "";
	bottom: -38px;
	left: 50%;
	width: 80px;
	height: 2px;
	background-color: #333;
	margin-left: -40px;
}
.what-we-say__text {
	color: #333;
	font-family: 'PFDinDisplayPro-Light';
	font-size: 20px;
	line-height: 28px;
	@include maxWidth(570px);
	text-align:center;
}
.slick-slider-nav div.slick-slide {
	margin-right: 20px;
}
.slick-slider-nav .slick-prev {
	background:url('../img/slick-nav-arrows.png') no-repeat;
	background-size: 100% 100%;
	width: 60px;
	height: 60px;
	transform: rotate(180deg);
	position:relative;
	z-index:1;
	left: 510px;
	top: -138px;
}
.slick-slider-nav .slick-next {
	background:url('../img/slick-nav-arrows.png') no-repeat;
	background-size: 100% 100%;
	width: 60px;
	height: 60px;
	position:relative;
	z-index:1;
	left: 1350px; 
	top: -618px;
}
.slick-slider-nav .slick-prev:before,
.slick-slider-nav .slick-next:before {
	display:none;
}
.slick-slider-nav .slick-prev.slick-disabled,
.slick-slider-nav .slick-next.slick-disabled{
	opacity:0.4
}
// What-we-say Display

// Number-lr Display
.number-lr {
	background: url('../img/number-lr.png') no-repeat;
}
.number-lr .wrapper-section{
	padding: 150px 0 128px;
}
.number-lr__title {
	color: #fff;
	@include h__title(70px, 80px);
	@include maxWidth(750px);
	@include dog_white();
	margin-bottom: 100px;
	&:before{
		top: -38px;
	}
	&:after{
		top: -27px;
	}	
}
.number-column {
	@include disInline();
	width: 33%;
}
.number-column:last-child {
	float: right;
    width: 25%;
}
.number-column__num {
	@include disInline();
	color: #fff;
	font-family: 'PFDinDisplayPro-Light';
	font-size: 120px;
}
.number-column__num .plus{
	font-size: 50px;
	vertical-align:top;
	padding-top: 40px;
    display: inline-block;
}
.number-column__num .from{
	font-size: 30px;
}
.number-column__des {
	@include disInline();
	color: #fff;
	font-family: 'PFDinDisplayPro-Light';
	font-size: 20px;
	line-height: 28px;
	margin: 25px 0 0 10px;
}
.number-column__des span{
	font-family: 'PFDinDisplayPro-Med';
}
// Number-lr Display

// How-we-do Display
.how-we-do-lr .wrapper-section{
	padding: 97px 0 220px;
}
.how-we-do-lr__title {
	color: #333;
	@include h__title(50px, 80px);
	@include maxWidth(737px);
	position:relative;
	z-index: 1;
	padding-bottom:19px;
	&:before{
		content: "";
		display: block;
		position:absolute;
		z-index: 2;
		left: -30px;
		top: 47px;
		background: url('../img/how-we-do__title_arrow.png') no-repeat;
		background-size: 100% 100%;
		width: 42px;
		height: 76px;
	}
	&:after{
		content: "";
		display: block;
		position:absolute;
		z-index: 2;
		right: -30px;
		top: 47px;
		background: url('../img/how-we-do__title_arrow.png') no-repeat;
		background-size: 100% 100%;
		width: 42px;
		height: 76px;
		transform: rotateY(180deg)
	}
}
.how-we-do-lr__who-work {
	color: #333;
	font-family: 'PFDinDisplayPro-Light';
	font-size: 28px;
	line-height: 38px;
	text-align:center;
	margin-bottom: 55px;
}
.how-we-do-lr__list {
	color: #333;
	font-family: 'PFDinDisplayPro-Light';
	font-size: 24px;
	line-height: 38px;
	padding: 0;
}
.how-we-do-lr__list li{
	@include disInline();
	width:24.5%;
	text-align: center;
}
.how-we-do-lr__list .wrapper-img {
	height: 160px;
	margin-bottom:6px;
}
.zig-work {
	text-align:center;
	position:relative;
	z-index: 1;
	margin-top:74px;
}
.curve-arrowF,
.curve-arrowS,
.curve-arrowT{
	position:absolute;
	z-index: 3;
	top:0;
	left:0;
	width: 74px;
	height: 135px;
}
.curve-arrowF{
	left:43%;
	top: 30px;
}
.curve-arrowS{
	left:51%;
	top: 240px;
}
.curve-arrowT{
	left:43%;
	top: 430px;
}
.text-block {
	position:absolute;
	z-index: 2;
	max-width: 390px;
}
.text-block.first {
	top: 0;
	right: 91px;
	text-align:left;
}
.text-block.second {
	top: 207px;
	left: 90px;
	text-align:right;
}
.text-block.third {
	top: 411px;
	right: 91px;
	text-align:left;
}
.text-block.four {
	top: 590px;
	left: 90px;
	text-align:right;
}
.text-block__title {
	color: #333;
	@include h__title(20px, 28px);
	text-align:inherit;
}
.text-block__des {
	color: #333;
	font-family: 'PFDinDisplayPro-Light';
	font-size: 20px;
	line-height: 30px;
}
.zig__list {
	width: 151px; 
	margin: 0 auto;
	padding:0;
}
.zig__list li{
	height: 200px;
	position:relative;
	z-index:1;
}
.zig__list li:nth-child(2n){
	text-align:left;
}
.zig__list li:nth-child(2n+1){
	text-align:right;
}
.zig__list li.line1:before{
	background: #c8c8c8;
	position:absolute;
	z-index:2;
	content:"";
	display:block;
	width:2px;
	height: 170px;
    transform: rotate(25deg);
    top: 45px;
    left: 72px;
}
.zig__list li.line2:before{
	background: #c8c8c8;
	position:absolute;
	z-index:2;
	content:"";
	display:block;
	width:2px;
	height: 160px;
    transform: rotate(-25deg);
    top: 50px;
    left: 72px;
}
.zig__list li.line3:before{
	background: #c8c8c8;
	position:absolute;
	z-index:2;
	content:"";
	display:block;
	width:2px;
	height: 160px;
    transform: rotate(25deg);
    top: 47px;
    left: 71px;
}
// How-we-do Display

// Why-moving Display
.why-moving-lr{
	background-color: #f8eeda;
}
.why-moving-lr .wrapper-section{
	padding: 110px 0 113px;
}
.why-moving-lr__title {
	color: #333;
	@include h__title(50px, 60px);
	@include maxWidth(711px);
	margin-bottom: 27px;
}
.why-moving-lr__good-story {
	color: #333;
	font-family: 'PFDinDisplayPro-Light';
	font-size: 28px;
	line-height: 38px;
	@include maxWidth(588px);
	text-align:center;
	margin-bottom: 50px;
}
.why-moving-lr__basic-cost {
	color: #333;
	font-family: 'PFDinDisplayPro-Light';
	font-size: 22px;
	line-height: 28px;
	text-align:center;
	margin-bottom: 30px;
}
.why-moving-lr__list {
	padding: 0;
	@include maxWidth(990px);
	margin-bottom: 99px;
}
.why-moving-lr__list li{
	display: inline-block;
	width:100%;
	max-width: 320px;
	margin-right: 10px;
	position:relative;
	z-index:1;
}
.why-moving-lr__list li:last-child{
	margin-right: 0;
}
.why-moving-lr__list img{
	width:100%;
}
.why-moving-lr__list li div{
	position:absolute;
	z-index: 2;
	opacity: 0.9;
	background-image: linear-gradient(to top, black 0%, transparent 100%);
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	padding-top: 92%;
}
.why-moving-lr__list li div:hover{
	opacity: 0;
}
.why-moving-lr__list li div.why-moving-lr__soc{
	padding-top: 100%;
}
.why-moving-lr__list li p{
	color: #fff;
	font-family: 'PFDinDisplayPro-Light';
	font-size: 28px;
	line-height: 28px;
	text-align:center;
	position:absolute;
	z-index:5;
	bottom: 50px;
	width: 100%;
}
.why-moving-lr__list li p:before{
	position: absolute;
	z-index: 4;
	display:block;
	content:"";
	bottom: -20px;
	left:50%;
	width: 80px;
	height: 2px;
	background-color: white;
	margin-left: -40px;
}
.why-moving-lr__effect {
	color: #333;
	font-family: 'PFDinDisplayPro-Light';
	font-size: 28px;
	line-height: 38px;
	text-align:center;
	@include dog_black();
	@include maxWidth(722px);

	&:before{
		top:-25px;
		left: -185px;
	}
	&:after{
		top:-18px;
		right: -185px;
	}
}
// Why-moving Display

// How-work Display
.how-work-lr{
	background:url("../img/how-work-bg.png") no-repeat;
	background-position: 0 100%;
}
.how-work-lr .wrapper-section{
	padding: 108px 0 370px;
}
.how-work-lr__title {
	color: #333;
	@include h__title(50px, 60px);
	@include maxWidth(1042px);
	padding-bottom: 108px;
}
.how-work-lr__slick .slick-slide{
	max-width:1170px;
}
.how-work-lr__slick .slick-prev,
.how-work-lr__slick .slick-next{
	width:100%;
	max-width: 310px;
	height: 62px;
	border: 1px solid #333;
	border-radius: 31px;
	color: #333;
	font-family: PFBeauSansPro;
	font-size: 18px;
	font-weight: 700;
	line-height: 38px;
	text-transform: uppercase;
	text-align:center;
	bottom: -150px;
	top: auto;
	z-index: 100;
}
.how-work-lr__slick .slick-prev{
	left:266px;
}
.how-work-lr__slick .slick-prev:before{
	content:'';
	position:absolute;
	background-size: 100% 100%;
	width: 7px;
	height: 14px;
	transform: rotate(180deg);
	left: 30px;
	top: 50%;
	margin-top: -7px;
}
.how-work-lr__slick .slick-next{
	right:266px;
}
.how-work-lr__slick .slick-next:before{
	content:'';
	position:absolute;	
	background-size: 100% 100%;
	width: 7px;
	height: 14px;
	right: 30px;
	top: 50%;
	margin-top: -7px;
}
.how-work-lr__slick .slick-prev:hover,
.how-work-lr__slick .slick-next:hover{
	color: #ffcb2d;
	border-color: #ffcb2d; 
}
.work-top .left {
	@include disInline();
	width: 45%;
	position: relative;
	z-index:1;
}
.work-top .right {
	@include disInline();
	width: 54%;
	position:relative;
	z-index:2;
	left: -60px;
	padding-top: 10px;
}
.rImg{
	display:block;
}
.left__example {
	color: #333;
	font-family: 'PFDinDisplayPro-Light';
	font-size: 22px;
	line-height: 38px;
	margin-bottom:23px;
}
.left__title {
	color: #333;
	font-family: 'PFBeauSansPro-Bold';
	font-size: 32px;
	line-height: 42px;
	margin-bottom: 24px;
}
.left__description {
	color: #333;
	font-family: 'PFDinDisplayPro-Light';
	font-size: 20px;
	line-height: 30px;
	margin-bottom: 31px;
}
.work-top .left a{
	color: #333;
	font-family: 'PFDinDisplayPro-Light';
	font-size: 18px;
	font-weight: 300;
	line-height: 20px;
	text-decoration: underline;
	display:inline-block;
	padding: 5px 0 0;
}
.work-top .left img{
	float:left;
	margin-right:10px;
	margin-bottom:10px;
}
.work-top .left .left{
	position:static;
	left: 0;
}
.work-top .left .right{
	position:static;
	left: 0;
	padding-top: 0;
}
.work-middle__title {
	color: #333;
	font-family: 'PFDinDisplayPro-Light';
	font-size: 35px;
	line-height: 38px;
	text-align:center;
	padding: 110px 0 137px;
}
.work-middle__list {
	padding: 0;
}
.work-middle__list li{
	@include disInline();
	width: 49%;
	position: relative;
	z-index: 1;
	padding-left: 90px;
	margin-bottom: 154px;
	&:before{
		position: absolute;
		z-index: 2;
		left: 0;
		top: -73px;
		content: "";
		display: block;
		width: 75px;
		height: 75px;
	}
	&.first:before{
		background: url("../img/how-work-list1.png") no-repeat;
		background-size: 100% 100%;
	}
	&.second:before{
		background: url("../img/how-work-list2.png") no-repeat;
		background-size: 100% 100%;
	}
	&.third:before{
		background: url("../img/how-work-list3.png") no-repeat;
		background-size: 100% 100%;
	}
	&.four:before{
		background: url("../img/how-work-list4.png") no-repeat;
		background-size: 100% 100%;
	}
	&:after{
		position: absolute;
		z-index: 2;
		left: 37.5px;
		top: 1px;
		content: "";
		display: block;
		width: 1px;
		height: 100px;
		background-color: #ebebeb;
	}
}
.work-middle__list h4{
	color: #333;
	font-family: 'PFBeauSansPro-Light';
	font-size: 20px;
	line-height: 28px;
	text-transform: uppercase;
	margin-bottom: 14px;
}
.work-middle__list p{
	color: #333;
	font-family: 'PFDinDisplayPro-Light';
	font-size: 18px;
	line-height: 24px;
	max-width: 402px;
}
.pay-back {
	width: 100%;
	max-width: 1170px;
	height: 280px;
	background-color: white;
	box-shadow: 1px 1.7px 10px rgba(1, 1, 1, 0.03);
	padding: 55px 0;
}
.pay-back__title  {
	color: #333;
	font-family: 'PFDinDisplayPro-Light';
	font-size: 28px;
	line-height: 38px;
	text-align:center;
	margin-bottom: 45px;
}
.wrapper-scale {
	max-width: 975px;
	height: 60px;
	background-color: whitesmoke;
	border-radius: 30px;
	box-shadow: inset 0.5px 0.9px 2px rgba(1, 1, 1, 0.1);
	margin: 0 auto;
	overflow: hidden;
}
.scale{
	background: url("../img/scale-bg.png");
	height: 100%;
	width: 40.7%;
}
.scale.scale2{
	background: url("../img/scale-bg.png");
	height: 100%;
	width: 44.1%;
}
.scale.scale3{
	background: url("../img/scale-bg.png");
	height: 100%;
	width: 88.2%;
}
.scale-list {
	padding:0;
	max-width: 975px;
	margin: 0 auto;
}
.scale-list li{
	display: inline-block;
	color: #b9b9b9;
	font-family: 'PFDinDisplayPro-Light';
	font-size: 13px;
	line-height: 40px;
	margin-left: 16px;
	position: relative;
	z-index: 1;
}
.scale-list li:before{
	content:"";
	display: block;
	position: absolute;
	z-index: 2;
	width: 1px;
	height: 11px;
	top: 0;
	left: 50%;
	background-color: #ebebeb;
}
.scale-list_bold{
	font-family: 'PFDinDisplayPro-Med' !important;
	color:#000 !important;	
}
.how-work-lr__review {
	display:none;
}
.how-work-lr__review h4{
	font-family: 'PFDinDisplayPro-Med';
	font-size: 24px;
}
.how-work-lr__review h5{
	font-family: 'PFDinDisplayPro-Light';
	font-size: 18px;
}
.how-work-lr__review p{
	font-family: 'PFDinDisplayPro-Light';
	font-size: 21px;
}
// How-work Display

// How-match Display
.how-match-lr{
	background: url('../img/how-match-bg.png') no-repeat;
	text-align:center;
}
.how-match-lr .wrapper-section{
	padding:136px 0 157px;
}
.how-match-lr__title{
	color: #fff;
	@include h__title(50px, 80px);
	margin-bottom: 63px;
}
.how-match-lr__text {
	color: #fff;
	font-family: 'PFDinDisplayPro-Light';
	font-size: 28px;
	line-height: 38px;
	@include dog_white();
	@include maxWidth(860px);
	text-align:center;
	&:before{
		top:-25px;
		left: -150px;
	}
	&:after{
		top:-18px;
		right: -150px;
	}
	margin-bottom:93px;
}
.how-match-lr__get-inquiry {
	@include disInline();
	background-color: #ffcb2d;
	border-radius: 31px;
	width: 100%;
	max-width: 236px;
	margin: 0 28px 0 0;
	text-align: center;
	padding: 10px 0;
	color: #333;
	font-family: 'PFBeauSansPro-Bold';
	font-size: 18px;
	line-height: 38px;
	text-transform: uppercase;
	&:hover{
		color: #ffcb2d;
		border: 1px solid #ffcb2d;
		background: rgba(0,0,0,0) 
	}
	&:active{
		color: #333;
		border: 1px solid #ffcb2d; 
		background-color: #ffc000;
	}
}
.how-match-lr__to-order-call-back {
	@include disInline();
	@include order_btn(10px, 26px);
	font-size: 18px;
	border-radius: 31px;
	&:hover{
		color: #ffcb2d;
		border: 1px solid #ffcb2d; 
	}
	&:active{
		color: #333;
		border: 1px solid #ffcb2d; 
		background: #ffcb2d; 
	}
}
// How-match Display

// Main-writer Display
.main-writer-lr .wrapper-section {
	padding: 117px  0 118px;
}
.main-writer-lr__title{
	color: #333;
	@include maxWidth(1030px);
	@include h__title(50px, 60px);
	margin-bottom: 83px;
}
.main-writer-lr__title span{
	display:block;
}
.main-writer-lr__left,
.main-writer-lr__right {
	@include disInline();
	width: 49%;
}
.main-writer-lr__right {
	padding: 32px 0 0 43px;
}
.main-writer-lr__right h5{
	color: #333;
	font-family: 'PFDinDisplayPro-Light';
	font-size: 28px;
	line-height: 24px;
	margin-bottom: 32px;
}
.main-writer-lr__right p{
	color: #333;
	font-family: 'PFDinDisplayPro-Light';
	font-size: 20px;
	line-height: 30px;
}
.main-writer-lr__right span{
	display: block;
	margin-bottom: 20px;
}
.main-writer-lr__right p.read-more {
	margin-bottom: 80px;
}
.main-writer-lr__right p.read-more img{
	float:left;
	margin-right: 20px;
}
.main-writer-lr__right a{
	color: #333;
	font-family: 'PFDinDisplayPro-Light';
	font-size: 18px;
	font-weight: 300;
	line-height: 22px;
	text-decoration: underline;
	vertical-align:top;
	padding-top: 10px;
	display:inline-block;
	cursor:pointer;
}
.main-writer-lr__right p.i-write-for{
	color: #333;
	font-family: 'PFDinDisplayPro-Light';
	font-size: 28px;
	line-height: 24px;
	margin-bottom: 11px;
}
.main-writer-lr__right  span.hide-text {
	display:none;
}
.slick-write .slick-next{
	background: url("../img/bottom-slider-arrow.png") no-repeat;
	width: 12px;
	height: 25px;
	top:50%;
	right: -10px;
	&:before{
		display: none;
	}
}
.slick-write .slick-prev{
	background: url("../img/bottom-slider-arrow.png") no-repeat;
	width: 12px;
	height: 25px;
	transform:rotate(180deg);
	top:50%;
	margin-top: -13px;
	&:before{
		display: none;
	}
}
// Main-writer Display

// Bottom-form Display
.bottom-form {
	background: url('../img/bottom-form-bg.png') no-repeat;
}
.bottom-form .wrapper-section{
	padding: 93px 0 119px;
}
.bottom-form__title {
	color: #fff;
	@include h__title(50px, 80px);
	margin-bottom: 40px;
}
.bottom-form__list {
	padding: 0;
	@include maxWidth(875px);
	margin-bottom: 63px;
}
.bottom-form__list li{
	@include disInline();
	color: #fff;
	font-family: 'PFBeauSansPro-Light';
	line-height: 50px;
	font-size: 25px;
	& a{
		font-size: 35px;
		color: #fff;
	}
}
.bottom-form__list li:first-child{
	margin-right: 61px;
	position:relative;
	z-index: 1;
	&:after{
		display: block;
		position:absolute;
		z-index: 2;
		content: "";
		width: 1px;
		height: 40px;
		opacity: 0.5;
		background-color: white;
		top: 5px;
		right: -40px;
	}
}
.bottom-form__form {
	width: 540px;
	height: 516px;
	background-color: rgba(56, 39, 23, 0.1);
	border: 1px solid white;
	margin: 0 auto;
	padding-top: 59px;
}
.bottom-form__form input {
	width: 420px;
	height: 62px;
	background-color: white;
	display:block;
	color: #333;
	font-family: 'PFDinDisplayPro-Light';
	font-size: 18px;
	line-height: 24px;
	border: 1px solid #fff;	
	margin: 0 auto 25px;
	padding-left: 30px;
}
.bottom-form__form input::-webkit-input-placeholder {color: #333;}
.bottom-form__form input:-moz-placeholder {color: #333;}
.bottom-form__form textarea::-webkit-input-placeholder {color: #333;}
.bottom-form__form textarea:-moz-placeholder {color: #333;}

.bottom-form__form textarea {
	width: 420px;
	height: 120px;
	background-color: white;
	display:block;
	color: #333;
	font-family: 'PFDinDisplayPro-Light';
	font-size: 18px;
	line-height: 24px;
	border: 1px solid #fff;	
	margin: 0 auto 40px;
	padding: 20px 0 0 30px;
}
.bottom-form__form input.input-submit {
	background-color: #ffcb2d;
	border-radius: 31px;
	width: 100%;
	max-width: 222px;
	margin: 0 auto;
	text-align: center;
	padding: 10px 0;
	color: #333;
	font-family: 'PFBeauSansPro-Bold';
	font-size: 18px;
	line-height: 38px;
	text-transform: uppercase;
	border: 1px solid #ffcb2d;
	cursor:pointer;
	&:hover{
		color: #ffcb2d;
		border: 1px solid #ffcb2d;
		background: rgba(0,0,0,0) 
	}
	&:active{
		color: #333;
		border: 1px solid #ffcb2d; 
		background-color: #ffc000;
	}
}
// Bottom-form Display

// Footer Display
.footer__logo{
	@include disInline();
    color: #333;
    font-family: 'PFBeauSansPro-Bold';
    font-size: 24px;
    line-height: 38px;
    text-transform: uppercase;
    padding: 16px 0 19px;
}
.footer__logo span{
	font-family: 'PFBeauSansPro-Light';
	font-size: 10px;
	line-height: 30px;
	vertical-align: top;
}
.footer p {
	float:right;
	color: #333;
	font-family: 'PFBeauSansPro-Light';
	font-size: 16px;
	line-height: 38px;
	padding-top:17px;
}
// Footer Display

/* Modal*/
div#overlay {
  display: none; 
}
div#overlay.open {
  display: block;
  position: fixed;
  z-index: 200;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vw;
  background: rgba(0, 0, 0, 0.5); 
}
div#modal-form {
  display: none;
  width: 661px;
  height: auto;
  background-color: rgba(255, 255, 255, 0.98);
}
div#modal-form form {
  position: relative; 
  height: auto;
   background-color: rgba(255, 255, 255, 0.98);
}
div#modal-form form a.close {
  position: absolute;
  z-index: 203;
  right: -105px;
  top: -35px;
  cursor: pointer;
}
div#modal-form form h2 {
  color: #24202f;
	font-family: 'pfdp-regular';
	font-size: 35px;
	text-align: center;
	margin:0;
	padding: 0 0 50px;
	text-transform: uppercase
}
div#modal-form form h3 {
  color: #24202f;
	font-family: 'pfdp-regular';
	font-size: 21px;
	margin:0;
	text-align: center;
	padding-bottom: 35px;
}
div#modal-form .bottom-form__form input {
	border: 1px solid #ebebeb;
}
div#modal-form .bottom-form__form textarea {
	border: 1px solid #ebebeb;
}
div#modal-form form input.error {  
	border: 1px solid red !important;	
}
div#modal-form form .input::-webkit-input-placeholder {color: rgba(36, 32, 47, 0.9);}
div#modal-form form .input:-moz-placeholder {color: rgba(36, 32, 47, 0.9);}
div#modal-form form .input.error::-webkit-input-placeholder {color: red;}
div#modal-form form .input.error:-moz-placeholder {color: red;}
div#modal-form form span {
  color: #fcfafe;
  font-family: "OpenSans-SemiBold";
  font-size: 15px;
  font-weight: 600;
  display: block;
  text-align: center; 
}
div#modal-form form .input-submit {
	margin: 38px auto 30px;
	border: none;
	&:hover{
		color: #ffcb2d;
		border: 1px solid #ffcb2d;
		background: rgba(0,0,0,0) 
	}
	&:active{
		color: #333;
		border: 1px solid #ffcb2d; 
		background-color: #ffc000;
	}
}
div#modal-form form p {
  color: #24202f;
	font-family: 'pfdp-regular';
	font-size: 21px;
	line-height: 25px;
	text-align: center;
	max-width: 400px;
	margin: 0 auto;
	padding-bottom: 60px;
}
div#modal-form.open {
  display: block;
  position: fixed;
  z-index: 201;
  top: 50px;
  left: 50%;
  margin-left: -366px; 
}



div#modal-form2 {
  display: none;
  width: 661px;
  height: auto;
  background-color: rgba(255, 255, 255, 0.98);
}
div#modal-form2 form {
  position: relative; 
  height: auto;
   background-color: rgba(255, 255, 255, 0.98);
}
div#modal-form2 form a.close {
  position: absolute;
  z-index: 203;
  right: -105px;
  top: -35px;
  cursor: pointer;
}
div#modal-form2 form h2 {
  color: #24202f;
	font-family: 'pfdp-regular';
	font-size: 35px;
	text-align: center;
	margin:0;
	padding: 0 0 50px;
	text-transform: uppercase
}
div#modal-form2 form h3 {
  color: #24202f;
	font-family: 'pfdp-regular';
	font-size: 21px;
	margin:0;
	text-align: center;
	padding-bottom: 35px;
}
div#modal-form2 .bottom-form__form input {
	border: 1px solid #ebebeb;
}
div#modal-form2 .bottom-form__form textarea {
	border: 1px solid #ebebeb !important;
}
div#modal-form2 form input.error {  
	border: 1px solid red !important;	
}
div#modal-form2 form .input::-webkit-input-placeholder {color: rgba(36, 32, 47, 0.9);}
div#modal-form2 form .input:-moz-placeholder {color: rgba(36, 32, 47, 0.9);}
div#modal-form2 form .input.error::-webkit-input-placeholder {color: red;}
div#modal-form2 form .input.error:-moz-placeholder {color: red;}
div#modal-form2 form span {
  color: #fcfafe;
  font-family: "OpenSans-SemiBold";
  font-size: 15px;
  font-weight: 600;
  display: block;
  text-align: center; 
}
div#modal-form2 form .input-submit {
	margin: 38px auto 30px;
	border: none;
	&:hover{
		color: #ffcb2d;
		border: 1px solid #ffcb2d;
		background: rgba(0,0,0,0) 
	}
	&:active{
		color: #333;
		border: 1px solid #ffcb2d; 
		background-color: #ffc000;
	}
}
div#modal-form2 form p {
  color: #24202f;
	font-family: 'pfdp-regular';
	font-size: 21px;
	line-height: 25px;
	text-align: center;
	max-width: 400px;
	margin: 0 auto;
	padding-bottom: 60px;
}
div#modal-form2.open {
  display: block;
  position: fixed;
  z-index: 201;
  top: 50px;
  left: 50%;
  margin-left: -366px; 
}
.input-name.error,
.input-mail.error{
	border: 1px solid red;
}
/* Modal*/

//Media
@media screen and (max-width:1920px){
.slick .item {height:55.47916666666667vw !important;background-size:cover !important;}
.item__title{top: 5.104166666666667vw;right: 1.3541666666666667vw;}
.item__cause{top: 22.8125vw;left: 19.322916666666668vw;}
.item__list {top: 16.145833333333336vw;left: 42.354166666666664vw;}
.slick-slider-nav .slick-prev {left: 26.5625vw;}
.slick-slider-nav .slick-next {left: 70.3125vw;}
.how-work-lr__slick .slick-slide {max-width: 100%;}
}
@media screen and (max-width:1366px){
.wrapper-section {max-width: 1200px;padding: 0 15px;}
.main-nav ul {padding: 28px 0 0 6.734992679355783vw;}
.main-nav li {margin-left: 3.074670571010249vw;}
.selling-lr__title {line-height: 5.417276720351391vw;max-width: 67.8623718887262vw;font-size: 5.124450951683748vw;padding: 16.325036603221083vw 0 38px;}	
.selling-lr__dog-text {line-height: 2.781844802342606vw;font-size: 2.1961932650073206vw;max-width: 58.565153733528554vw;}
.selling-lr__dog-text:before {left: -10.980966325036604vw;top: -4.24597364568082vw;width: 11.273792093704246vw;height: 9.66325036603221vw;}
.selling-lr__dog-text:after {right: -10.980966325036604vw;top: -3.4407027818448026vw;width: 10.102489019033674vw;height: 9.077598828696926vw;}
.bussines-lr .wrapper-section{padding-bottom: 9.224011713030746vw;}
.bussines-lr__title {font-size: 3.6603221083455346vw;line-height: 5.856515373352855vw;padding-top: 7.247437774524158vw;}
.bussines-lr__title-bottom {font-size: 2.049780380673499;line-height: 3.3674963396778916vw;margin-bottom: 6.734992679355783vw;}
.bussines-column img {max-width: 150px;width: 100%;}
.bussines-lr__dog-text {font-size: 2.049780380673499vw;line-height: 2.781844802342606vw;max-width: 58.27232796486091vw;margin: 11.273792093704246vw auto 0;}
.bussines-lr__dog-text:before {top: -1.8301610541727673vw;left: -13.543191800878477vw;width: 14.055636896046853vw;height: 9.66325036603221vw;}
.bussines-lr__dog-text:after {top: -1.3177159590043925vw;right: -13.543191800878477vw;width: 13.469985358711567vw;height: 9.077598828696926vw;}
.item__title {top: 1.104166666666667vw;right: 1.3541666666666667vw;font-size: 3.6603221083455346vw;line-height: 5.856515373352855vw;}
.item__cause {top: 22.8125vw;left: 10.322916666666668vw;font-size: 3.6603221083455346vw;line-height: 4.392386530014641vw;}
.item__list {top: 16.145833333333336vw;left: 42.354166666666664vw;font-size: 1.610541727672035vw;line-height: 2.049780380673499vw;border-radius: 1.4641288433382138vw;padding: 4.026354319180088vw 0 4.026354319180088vw 5.124450951683748vw;}
.item__list li {max-width: 24.963396778916543vw;margin-bottom: 3.513909224011713vw;}
.item__list li:before {left: -2.9282576866764276vw;font-size: 2.1961932650073206vw;line-height: 2.049780380673499vw;width: 2.1961932650073206vw;height: 2.1961932650073206vw;}
.slick .slick-next {width: 4.392386530014641vw;height: 4.392386530014641vw;margin-top: -2.1961932650073206vw;}
.engine-lr__title {font-size: 3.6603221083455346vw;line-height: 4.392386530014641vw;max-width: 46.120058565153734vw;padding: 8.784773060029282vw 0 5.783308931185944vw;}
.engine-column:before {font-size: 1.171303074670571vw;line-height: 1.7569546120058566vw;}
.engine-lr__text {font-size: 1.8301610541727673vw;line-height: 2.4890190336749636vw;}
.engine-column.first {padding-top: 2.635431918008785vw;}
.engine-column.first:after {width: 16.910688140556367vw;height: 15.812591508052709vw;top: -10.029282576866764vw;left: 6.002928257686676vw;}
.engine-column.first:before {width: 13.469985358711567vw;height: 15.812591508052709vw;top: -6.588579795021962vw;left: 7.686676427525622vw;}
.engine-column.second {padding-right: 2.2693997071742316vw;}
.engine-column.second:before {width: 10.248901903367496vw;height: 12.371888726207906vw;top: -6.222547584187408vw;right: 9.150805270863836vw;}
.engine-column.second:after {width: 13.250366032210833vw;height: 12.371888726207906vw;top: -10.029282576866764vw;right: 7.979502196193265vw;}
.engine-column.third {padding: 10.468521229868228vw 0 0 16.691068814055637vw;}
.engine-column.third:before {width: 12.445095168374817vw;height: 14.641288433382138vw;top: 2.1961932650073206vw;right: 5.124450951683748vw;}
.engine-column.third:after {width: 15.666178623718888vw;height: 14.641288433382138vw;top: -1.0980966325036603vw;right: 3.6603221083455346vw;}
.engine-column.four {padding: 9.077598828696926vw 10.980966325036604vw 0 0;}
.engine-column.four:before {width: 16.105417276720353vw;height: 18.374816983894583vw;left: 8.052708638360176vw;}
.engine-column.four:after {width: 19.765739385065885vw;height: 18.374816983894583vw;top: -4.758418740849195vw;left: 5.856515373352855vw;}
.what-we-say__title {font-size: 3.6603221083455346vw;line-height: 4.392386530014641vw;max-width: 51.317715959004396vw;padding-top: 14.860907759882869vw;}
.what-we-say__your-history {font-size: 2.049780380673499vw;line-height: 2.1961932650073206vw;max-width: 34.40702781844802vw;margin-bottom: 4.758418740849195vw;}
.what-we-say__your-history:after {bottom: -2.781844802342606vw;}
.what-we-say__text {font-size: 1.4641288433382138vw;line-height: 2.049780380673499vw;max-width: 41.72767203513909vw;}
.slick-slider-nav .slick-prev {left: 20.5625vw; top: -10.102489019033674vw;}
.slick-slider-nav .slick-next {left: 74.3125vw; top: -605px;}
.number-lr .wrapper-section {padding: 10.980966325036604vw 15px 9.370424597364568vw;}
.number-lr__title {font-size: 5.124450951683748vw;line-height: 5.856515373352855vw;max-width: 54.904831625183014vw;margin-bottom: 7.320644216691069vw;}
.number-column__num {font-size: 8.784773060029282vw;}
.number-column__des {font-size: 1.4641288433382138vw;line-height: 2.049780380673499vw;margin: 1.8301610541727673vw 0 0 0.7320644216691069vw;}
.number-column__num .plus {font-size: 3.6603221083455346vw;padding-top: 2.9282576866764276vw;}
.number-column__num .from {font-size: 2.1961932650073206vw;}
.how-we-do-lr .wrapper-section {padding: 7.101024890190337vw 15px 16.105417276720353vw;}
.how-we-do-lr__title {font-size: 3.6603221083455346vw;line-height: 5.856515373352855vw;max-width: 53.95314787701317vw;padding-bottom: 1.390922401171303vw;}
.how-we-do-lr__title:before{left: -2.1961932650073206vw;top: 3.4407027818448026vw;width: 3.074670571010249vw;height: 5.563689604685212vw;}
.how-we-do-lr__title:after {right: -2.1961932650073206vw;top: 3.4407027818448026vw;width: 3.074670571010249vw;height: 5.563689604685212vw;}
.how-we-do-lr__who-work {font-size: 2.049780380673499vw;line-height: 2.781844802342606vw;margin-bottom: 4.026354319180088vw;}
.text-block.first {right: 6.661786237188873vw;}
.text-block.second {left: 6.588579795021962vw;}
.text-block.third {right: 6.661786237188873vw;}
.text-block.four {left: 6.588579795021962vw;}
.why-moving-lr .wrapper-section {padding: 8.052708638360176vw 15px 8.272327964860908vw;}
.why-moving-lr__title {font-size: 3.6603221083455346vw;line-height: 4.392386530014641vw;max-width: 52.049780380673496vw;margin-bottom: 1.9765739385065886vw;}
.why-moving-lr__good-story {font-size: 2.049780380673499vw;line-height: 2.781844802342606vw;max-width: 43.04538799414349vw;margin-bottom: 3.6603221083455346vw;}
.why-moving-lr__basic-cost {font-size: 1.610541727672035vw;line-height: 2.049780380673499vw;margin-bottom: 2.1961932650073206vw;}
.why-moving-lr__list {max-width: 73.20644216691069vw;margin-bottom: 7.247437774524158vw;}
.why-moving-lr__list li {max-width: 23.42606149341142vw;}
.why-moving-lr__effect {font-size: 2.049780380673499vw;line-height: 2.781844802342606vw;max-width: 52.85505124450952vw;}
.why-moving-lr__effect:before {top: -1.8301610541727673vw;left: -13.543191800878477vw;width: 14.055636896046853vw;height: 9.66325036603221vw;}
.why-moving-lr__effect:after {top: -1.3177159590043925vw;right: -13.543191800878477vw;width: 13.469985358711567vw;height: 9.077598828696926vw;}
.how-work-lr .wrapper-section {padding: 7.906295754026354vw 15px 27.086383601756953vw;}
.how-work-lr__title {font-size: 3.6603221083455346vw;line-height: 4.392386530014641vw;max-width: 76.28111273792094vw;padding-bottom: 7.906295754026354vw;}
.left__example {font-size: 1.610541727672035vw;line-height: 2.781844802342606vw;margin-bottom: 1.6837481698389458vw;}
.left__title {font-size: 2.342606149341142vw;line-height: 3.074670571010249vw;margin-bottom: 1.7569546120058566vw;}
.left__description {font-size: 1.4641288433382138vw;line-height: 2.1961932650073206vw;margin-bottom: 2.2693997071742316vw;}
.work-top .left .left img {width: 3.6603221083455346vw;}
.work-top .left .right img {width: 3.6603221083455346vw;}
.work-top .left a {font-size: 1.3177159590043925vw;font-weight: 21.96193265007321vw;line-height: 1.4641288433382138vw;padding: 0.36603221083455345vw 0 0;}
.work-top .right {left: -4.392386530014641vw;}
.work-top .right img{width: 100%;}
.work-middle__title {font-size: 2.562225475841874vw;line-height: 2.781844802342606vw;padding: 8.052708638360176vw 0 10.029282576866764vw;}
.work-middle__list li {padding-left: 6.588579795021962vw;margin-bottom: 11.273792093704246vw;}
.work-middle__list li:before {top: -5.34407027818448vw;width: 5.490483162518302vw;height: 5.490483162518302vw;}
.work-middle__list li:after {left: 2.745241581259151vw;top: -0.07320644216691069vw;}
.work-middle__list h4 {font-size: 1.4641288433382138vw;line-height: 2.049780380673499vw;margin-bottom: 1.0248901903367496vw;}
.work-middle__list p {font-size: 1.3177159590043925vw;line-height: 1.7569546120058566vw;max-width: 29.428989751098094vw;}
.how-work-lr__slick .slick-prev {left: 19.472913616398245vw;max-width: 22.693997071742313vw;height: 4.538799414348463vw;border-radius: 2.2693997071742316vw;font-size: 1.3177159590043925vw;line-height: 2.781844802342606vw;bottom: -150px;}
.how-work-lr__slick .slick-next {right: 19.472913616398245vw;max-width: 22.693997071742313vw;height: 4.538799414348463vw;border-radius: 2.2693997071742316vw;font-size: 1.3177159590043925vw;line-height: 2.781844802342606vw;bottom: -150px;}
.how-match-lr .wrapper-section {padding: 9.956076134699854vw 15px 11.493411420204978vw;}
.how-match-lr__title {font-size: 3.6603221083455346vw;line-height: 5.856515373352855vw;margin-bottom: 4.612005856515373vw;}
.how-match-lr__text {font-size: 2.049780380673499vw;line-height: 2.781844802342606vw;max-width: 62.95754026354319vw;margin-bottom: 6.808199121522694vw;}
.how-match-lr__text:before {top: -1.8301610541727673vw;left: -10.980966325036604vw;width: 11.273792093704246vw;height: 9.66325036603221vw;}
.how-match-lr__text:after {top: -1.3177159590043925vw;right: -10.980966325036604vw;width: 10.102489019033674vw;height: 9.077598828696926vw;}
.main-writer-lr .wrapper-section {padding: 8.56515373352855vw 15px 8.638360175695462vw;}
.main-writer-lr__title {max-width: 75.40263543191801vw;font-size: 3.6603221083455346vw;line-height: 4.392386530014641vw;margin-bottom: 6.076134699853587vw;}
.main-writer-lr__left img {width: 100%;}
.main-writer-lr__right {padding: 2.342606149341142vw 0 0 3.1478770131771596vw;}
.main-writer-lr__right h5 {font-size: 2.049780380673499vw;line-height: 1.7569546120058566vw;margin-bottom: 2.342606149341142vw;}
.main-writer-lr__right p {font-size: 1.4641288433382138vw;line-height: 2.1961932650073206vw;}
.main-writer-lr__right span {margin-bottom: 1.4641288433382138vw;}
.main-writer-lr__right p.read-more {margin-bottom: 5.856515373352855vw;}
.main-writer-lr__right a {font-size: 1.3177159590043925vw;line-height: 1.610541727672035vw;padding-top: 0.7320644216691069vw;}
.main-writer-lr__right p.i-write-for {font-size: 2.049780380673499vw;line-height: 1.7569546120058566vw;margin-bottom: 0.8052708638360175vw;}
.bottom-form .wrapper-section {padding: 6.808199121522694vw 15px 8.711566617862372vw;}
.bottom-form__title {font-size: 3.6603221083455346vw;line-height: 5.856515373352855vw;margin-bottom: 2.9282576866764276vw;}
.bottom-form__list {max-width: 64.05563689604685vw;margin-bottom: 4.612005856515373vw;}
.bottom-form__list li:first-child {margin-right: 4.465592972181552vw;}
.bottom-form__list li {line-height: 3.6603221083455346vw;font-size: 1.8301610541727673vw;}
.bottom-form__list li a {font-size: 2.562225475841874vw;}
.zig-work {margin-top: 5.417276720351391vw;}
.pay-back img {display: block;width: 90vw;}
}
@media screen and (max-width:1150px){
.slick-slider-nav .slick-next {left: 74.3125vw; top: -590px;}
.text-block__title {font-size: 18px;line-height: 24px;}
.text-block__des {font-size: 18px;line-height: 26px;}
.text-block.first {right: 1.661786237188873vw;}
.text-block.second {left: 1.588579795021962vw;}
.text-block.third {right: 1.661786237188873vw;}
.text-block.four {left: 1.588579795021962vw;}
.text-block {max-width: 320px;}
.why-moving-lr__list li div p {font-size: 22px;line-height: 20px;}
}
@media screen and (max-width:1100px){
.main-nav ul {padding: 28px 0 0 1.734992679355783vw;}
.main-nav li {margin-left: 2.074670571010249vw;}
.work-top .left .right {width:49%;}
}
@media screen and (max-width:1030px){
.wrapper-scale {
	max-width: 100%;
	height: 60px;
}
.scale{
	width: 39vw;
}
.scale.scale2{
	width: 42.5vw;
}
.scale.scale3{
	width: 85vw;
}
.scale-list {
	padding:0;
	max-width: 975px;
	margin: 0 auto;
}
.scale-list li{
	margin-left: 1.535929203539823vw;
}
}
@media screen and (max-width:992px){
.main-header__logo {padding: 26px 0 0; margin-left: 80px;}
.main-nav ul{display:none;padding: 0; background: rgba(0,0,0,0.75); width: 100%;position:absolute;top:91px;left:0;z-index:5}
.main-nav li {display:block;margin-left: 0;text-align:center; padding:5px 0;}
.main-nav a {font-size: 18px;}
.header-top__button-menu {display: block;padding: 10px 15px;cursor: pointer;position:absolute;z-index:10; left:25px; top:25px;}
.selling-lr__title {line-height:53px;max-width: 100%;font-size: 50px;}
h2 {font-size: 35px !important;line-height:40px !important;max-width:100% !important;}
h2:before {display:none !important;}
h2:after {display:none !important;}
.bussines-column {width: 49%;margin-bottom:20px;}
.why-work-lr {background-color: #fff;}
.slick .item {width: 100% !important;height: 450px !important; margin-bottom: 20px;}
.item__title {top: 20px;right: 50%;margin-right: -250px;}
.item__cause {font-size: 28px;line-height: 33px}
.item__list {font-size: 14px;line-height: 18px;}
.item__list li:before {font-size: 18px;line-height: 22px;width: 20px;height: 20px;}
.engine-column.third {padding: 6.468521229868228vw 0 0 16.691068814055637vw;}
.engine-column.four {padding: 4.077598828696926vw 10.980966325036604vw 0 0;}
.engine-lr__text {font-size: 18px;line-height: 22px;}
.what-we-say__your-history {font-size: 22px;line-height: 24px;max-width: 350px;}
.what-we-say__text {font-size: 18px;line-height: 22px;max-width: 370px;}
.slick-slider-nav .slick-prev {left: 15.5625vw;}
.slick-slider-nav .slick-next {top: -575px; left: 78.3125vw;}
.number-column__num {font-size: 80px;}
.number-column__des {font-size: 16px;line-height: 22px;}
.number-column__num .plus {font-size: 36px;padding-top: 29px;}
.number-column__num .from {font-size: 22px;}
.number-column:last-child {width: 33%;}
.how-we-do-lr__list li {width: 49%; margin-bottom: 20px;}
.why-moving-lr__list { max-width: 100%;}
.why-moving-lr__list li {max-width: 31.9%;}
.why-moving-lr__effect {font-size: 18px;line-height: 24px;max-width: 410px;}
.why-moving-lr__effect:before,
.why-moving-lr__effect:after{display:none;}
.work-top .right {display:none;}
.work-top .left .right {display:inline-block;}
.work-top .left {width: 100%;}
.work-top .left .left {width: 49%;}
.left__title {font-size: 30px;line-height: 35px;}
.left__example {font-size: 16px;line-height: 20px;}
.left__description {font-size: 16px;line-height: 20px;}
.work-top .left .left img {width: 50px;}
.work-top .left .right img {width: 50px;}
.work-top .left a {font-size: 14px;line-height: 18px;padding: 10px 0 0;}
.work-middle__title {font-size: 30px;line-height: 35px;}
.work-middle__list li {width: 100%;}
.work-middle__list h4 {font-size: 25px;line-height: 30px;margin-bottom: 20px;}
.work-middle__list p {font-size: 22px;line-height: 24px;max-width: 100%;}
.how-work-lr__slick .slick-prev {max-width: 200px;height: 40px;border-radius: 20px;font-size: 13px;line-height: 15px;bottom: 0px;}
.how-work-lr__slick .slick-next {max-width: 200px;height: 40px;border-radius: 20px;font-size: 13px;line-height: 15px;bottom: 0px;}
.how-work-lr__slick .slick-prev:before{display:none} 
.how-work-lr__slick .slick-next:before{display:none}
.how-match-lr__text {font-size: 20px;line-height: 24px;max-width: 485px;} 
.how-match-lr__text:before,
.how-match-lr__text:after{display:none;}
.main-writer-lr__left, .main-writer-lr__right {width: 100%;}
.main-writer-lr__right h5 {font-size: 25px;line-height: 30px;}
.main-writer-lr__right p {font-size: 16px;line-height: 20px;}
.main-writer-lr__right a {font-size: 16px;line-height: 20px;padding-top: 10px;}
.main-writer-lr__right p.i-write-for {font-size: 25px;line-height: 30px;}
.bottom-form__list li:first-child:after {display: none;}
.bottom-form__list {max-width: 100%; text-align:center;}
.bottom-form__list li {line-height: 25px;font-size: 20px;}
.bottom-form__list li a {font-size: 30px;}
.how-we-do-lr__who-work {font-size: 20px;line-height: 25px;}
.why-moving-lr__good-story {font-size: 20px;line-height: 25px; max-width: 100%;text-align:center;}
.why-moving-lr__basic-cost {font-size: 16px;line-height: 24px;}
.scale{width: 39vw;}
.scale.scale2{width: 42.5vw;}
.scale.scale3{width: 85vw;}
.scale-list li{display:none;}
.scale-list li.scale-list_bold{display:block;margin-left: 38.5vw;}
.scale-list.scale2 li.scale-list_bold{margin-left: 42.0vw;}
.scale-list.scale3 li.scale-list_bold{margin-left: 84.5vw;}
.scale-list li:before {left: 7px;}
}
@media screen and (max-width:900px){
.slick-slider-nav .slick-next {top: -565px;}
.text-block {max-width: 280px}
.text-block.first {right: 0;}
.text-block.second {left: 0;}
.text-block.third {right: 0;}
.text-block.four {left: 0;}
}
@media screen and (max-width:769px){
.bussines-lr__title-bottom {font-size: 28px;line-height: 35px;}
.bussines-column p span {display: inline;}
.item__title {top: 20px;right: 0;margin-right: 0;text-align: center;}
.item__cause {left: 1.5vw;}
.item__list {top: 100px;left: 200px;padding-left: 35px;}
.item__list li {max-width: 200px;}
.engine-column {display: none;}
.what-we-say__list{display:none;}
.slick-slider-nav .slick-prev {left: 2.5625vw;top: -25.102489019033674vw;}
.slick-slider-nav .slick-next {top: -535px;left: 85vw;right: auto;}
.slick-slider-nav img{width:100%; margin: 0;}
.slick-slider-nav div.slick-slide {margin-right: 0;width: 100vw;}
.zig-work img{display:none;}
.text-block {position: static;text-align: center !important;width: 100% !important;max-width: 100% !important;margin-bottom: 20px;}
.why-moving-lr__list li {max-width: 100%; margin-bottom: 20px;}
.work-middle__list h4 {font-size: 20px;line-height: 25px;}
.work-middle__list p {font-size: 18px;line-height: 22px;}
.work-middle__list li:before, 
.work-middle__list li:after {display:none;}
.how-work-lr__slick .slick-prev {bottom: -75px; left: 50%; margin-left:-100px;}
.how-work-lr__slick .slick-next {bottom: -125px; right: 50%; margin-right:-100px;}
.what-we-say__title {margin-bottom: 20px;}
.how-match-lr__get-inquiry{display:block;margin: 0 auto 10px;}
.bottom-form__form {width: 100%;height: auto;padding: 20px 0;}
.bottom-form__form input {width: 90%;height: 40px;margin: 0 5% 25px;}
.bottom-form__form textarea {width: 90%;height: 120px;margin: 0 5% 40px;}
.bottom-form__form input.input-submit {height: 55px;}
.bottom-form__list li:first-child {margin: 0 0 15px 0;}
div#modal-form.open {width: 95%;left: 2.5%;margin:0;overflow-y: scroll;overflow-x:hidden; height:95vh;}
.zig__list{display:none;}
}
@media screen and (max-width:500px){
.selling-lr__title {line-height: 45px;font-size: 40px;}
h2 {font-size: 30px !important;line-height: 35px !important;}
.selling-lr__see-examples {margin: 60px auto;}
.bussines-column {width: 100%;margin-bottom: 20px;}
.slick-slider-nav .slick-next {top: -360px;left: 80vw;right: auto;}
.slick-slider-nav .slick-prev {left: 3vw;top: -1.502489019033674vw;}
.number-column {display: inline-block;vertical-align: top;width: 100% !important;float:none !important; text-align:center;}
.number-column__des {text-align:left;}
.main-header__logo {padding: 5px 0 0;margin-left: 85px;}
.header-top__button-menu {left: 15px;top: 25px;}
.order-lr {margin-top: 0;float: none;position:absolute; z-index:2; left: 80px; top: 40px;}
.item__cause {top: 100px;left: 0;width: 95vw;text-align: center;font-size: 24px;}
.item__cause span{display: inline;}
.item__list {top: 150px;left: 0;padding-left: 50px;border: none; font-size: 16px;}
.item__list li {max-width: 85vw;}
.item__list li:before {left: -20px;}
.how-work-lr__slick .slick-next {bottom: -40px;}
.how-work-lr__slick .slick-prev {bottom: -90px;z-index: 10;}
}
@media screen and (max-width:415px){
.slick-slider-nav .slick-next {top: -300px;}
.text-block__title {margin-bottom:10px;}
.left__title {font-size: 25px;line-height: 30px;}
.how-work-lr .wrapper-section {padding-bottom:112px;}
.footer p {font-size: 14px;}
}
@media screen and (max-width:400px){
html{overflow-x:hidden};
.selling-lr__title {line-height: 40px;font-size: 30px;}
h2 {font-size: 25px !important;line-height: 30px !important;}
.bussines-lr__title {margin-bottom:20px;}
.item__list li {max-width: 75vw;}
.engine-lr__text span {display: inline;}
.slick-slider-nav .slick-next {top: -240px;left:72vw;}
.work-top .left .left {width: 100%;}
.work-top .left .right {width: 100%;}
.work-top .left .right img {margin-bottom: 10px;}
.work-top .left .left img {margin-bottom: 10px;}
.how-match-lr__to-order-call-back {font-size: 14px;padding: 10px;}
.main-writer-lr__right h5 {text-align:center;}
.bottom-form__title {margin-bottom: 20px;}
.bottom-form__list li a {display:block;text-align:center; margin: 10px 0;}
.footer p {font-size: 10px;}
.footer__logo {font-size: 20px; padding: 16px 0 19px;}
}

